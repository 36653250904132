import fetchAPI from '../../fetchAPI';

export const callUpdateCoreMeta = async (
  locationId: string,
  data: Partial<CoreMeta>
): Promise<iResponse<any>> => {
  const response: iResponse<any> = await fetchAPI({
    method: `PUT`,
    route: `core/location/${locationId}/meta-data`,
    data
  });

  return response;
};

export default callUpdateCoreMeta;
