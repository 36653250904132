import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Grid, Box, Stack, LinearProgress, Select, MenuItem } from '@mui/material';
import LocationDetails from './accountDetails';
import {
  callGetCoreDetailedList,
  callGetSyncCoreUsers,
  callUpdateCoreMeta,
  callGetCoreMeta
} from '@api';
import CardList from './commonCard';
import CoreUsers from './users';
import CoreContacts from './contacts';
import { Alert, Button, Text, TextField } from '@atoms';
import CoreOffices from './offices';
import { toast } from 'react-toastify';

interface CoreDetailsProps extends RouteComponentProps {
  locationId?: string;
}

const CoreDetails: React.FC<CoreDetailsProps> = (props: CoreDetailsProps) => {
  useEffect(() => {
    apiGetCoreDetailedList();
    apiGetCoreMeta();
  }, [props.locationId]);

  const [loading, setLoading] = useState<boolean>(false);
  const [companyTokens, setCompanyToken] = useState<CoreLocation>();
  const [coreMeta, setCoreMeta] = useState<CoreMeta>();
  const [coreMetaSave, setCoreMetaSave] = useState<boolean>(false);

  const apiGetCoreDetailedList = async () => {
    setLoading(true);
    const { data, code } = await callGetCoreDetailedList({
      locationId: props.locationId
    });
    if (data && data.data.length > 0 && code == 200) {
      setCompanyToken(data.data[0]);
    }
    setLoading(false);
  };

  const apiGetCoreMeta = async () => {
    setLoading(true);
    const { data: dataCoreMeta, code: codeCoreMeta } = await callGetCoreMeta(props.locationId!);
    if (dataCoreMeta && codeCoreMeta == 200) {
      setCoreMeta(dataCoreMeta.data);
    }
    setLoading(false);
  };

  const apiSyncUsers = async () => {
    setLoading(true);
    const { data, code } = await callGetSyncCoreUsers(props.locationId as string);
    if (data && code == 200) {
      apiGetCoreDetailedList();
    }
    setLoading(false);
  };

  const onSwitchChange = async (key: string, value: CoreIntegrationType | string) => {
    setCoreMetaSave(true);
    setCoreMeta((state) => {
      if (!state) return state;
      return {
        ...state,
        [key]: value
      } as CoreMeta;
    });
  };

  const onSaveMeta = async () => {
    if (!coreMeta) return;
    setLoading(true);

    switch (coreMeta?.integration) {
      case 'reapit':
        if (!coreMeta?.reapitCustomerId?.trim())
          return toast.error('Reapit Customer ID is required');
        break;
      case 'homeflow':
        if (!coreMeta?.homeFlowToken?.trim()) return toast.error('Home Flow Token is required');
        break;
      case 'alto':
        if (!coreMeta?.altoAgentKey?.trim()) return toast.error('Agent Key is required');
        if (!coreMeta?.altoAgentId?.trim()) return toast.error('Agent ID is required');
        if (!coreMeta?.altoAgentUsername?.trim()) return toast.error('Agent Username is required');
        if (!coreMeta?.altoAgentPassword?.trim()) return toast.error('Agent Password is required');
        break;
      case 'leadpro':
        if (!coreMeta?.leadProApiKey?.trim()) return toast.error('LeadPro API Key is required');
        break;
      case 'acquaint':
        if (!coreMeta?.acquaintApiKey?.trim()) return toast.error('Acquaint API Key is required');
        if (!coreMeta?.acquaintSitePreFix?.trim()) return toast.error('Acquaint Site PreFix is required');
        break;
      case 'custom':
        if (!coreMeta?.customKey?.trim()) return toast.error('Custom Key is required');
        break;
      default:
        return 'Key';
    }

    const { data, code } = await callUpdateCoreMeta(props.locationId as string, {
      integration: coreMeta.integration,
      passThru: coreMeta.passThru,
      homeFlowToken: coreMeta.homeFlowToken,
      leadProApiKey: coreMeta.leadProApiKey,
      reapitCustomerId: coreMeta.reapitCustomerId,
      acquaintApiKey: coreMeta.acquaintApiKey,
      acquaintSitePreFix: coreMeta.acquaintSitePreFix,
      altoAgentId: coreMeta.altoAgentId,
      altoAgentKey: coreMeta.altoAgentKey,
      altoAgentName: coreMeta.altoAgentName,
      altoAgentPassword: coreMeta.altoAgentPassword,
      altoAgentUsername: coreMeta.altoAgentUsername,
      customKey: coreMeta.customKey
    });
    if (data && code == 200) {
      toast.success('Updated Successfully');
    }
    setCoreMetaSave(false);
    setLoading(false);
  };

  if (props.locationId === undefined) {
    return (
      <Box>
        <Stack>
          <Box sx={{ mb: 1 }}>
            <Alert message={'No location id found'} severity={'error'} />
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <Grid p={2} spacing={2} container>
      <Grid xs={12} item>
        <Stack direction={'row'} justifySelf={'right'}>
          <Stack direction={'row'} alignItems={'center'} mr={2}>
            <Text mr={1}>Pass Thru</Text>
            <Select
              value={coreMeta?.passThru ?? 'off'}
              onChange={(e) => onSwitchChange('passThru', e.target.value)}
              size="small"
              sx={{ backgroundColor: '#ffffff', fontSize: 14, height: '50' }}
              inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem key="off" value="off">
                Off
              </MenuItem>
              <MenuItem key="on" value="on">
                On
              </MenuItem>
              <MenuItem key="valuation" value="valuation">
                Valuation
              </MenuItem>
            </Select>
          </Stack>
          <Button primary={false} onClick={() => apiSyncUsers()}>
            <Text $fontColor="white">Sync Information With Core</Text>
          </Button>

          {coreMetaSave && (
            <Button primary={true} onClick={onSaveMeta} sx={{ ml: 2 }}>
              <Text $fontColor="white">Save</Text>
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid xs={12} item sx={{ mt: 2 }}>
        <Grid xs={12} container columnSpacing={0}>
          {loading && <LinearProgress />}
          <Grid xs={6} item>
            <LocationDetails location={companyTokens} />
          </Grid>

          <Grid xs={6} item>
            <Grid
              container
              xs={12}
              columnSpacing={2}
              sx={{
                pl: 1,
                pr: 2,
                height: '100%',
                backgroundColor: '#E8EDDF',
                boxShadow:
                  '0px 7px 140px rgba(141, 152, 121, 0.1), 0px 2px 32px rgba(141, 152, 121, 0.05), 0px 1px 10px rgba(141, 152, 121, 0.04)',
                borderRadius: '6px'
              }}>
              <Grid item xs={6}>
                <Stack mt={1.5}>
                  <Text mb={1} $fontSize={14}>
                    Integration
                  </Text>
                  <Select
                    value={coreMeta?.integration ?? 'none'}
                    onChange={(e) =>
                      onSwitchChange('integration', e.target.value as CoreIntegrationType)
                    }
                    size="small"
                    sx={{ backgroundColor: '#ffffff', fontSize: 14, height: '50' }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem key="none" value="none">
                      None
                    </MenuItem>
                    <MenuItem key="reapit" value="reapit">
                      Reapit
                    </MenuItem>
                    <MenuItem key="leadpro" value="leadpro">
                      LeadPro
                    </MenuItem>
                    <MenuItem key="homeflow" value="homeflow">
                      HomeFlow
                    </MenuItem>
                    <MenuItem key="alto" value="alto">
                      Alto
                    </MenuItem>
                    <MenuItem key="acquaint" value="acquaint">
                      Acquaint
                    </MenuItem>
                    <MenuItem key="custom" value="custom">
                      Custom
                    </MenuItem>
                  </Select>
                </Stack>
              </Grid>
              {coreMeta?.integration == 'reapit' && (
                <Grid item xs={12}>
                  <TextField
                    label={'Reapit Customer ID'}
                    name={'reapitCustomerId'}
                    value={coreMeta?.reapitCustomerId}
                    onChange={(e) => {
                      onSwitchChange('reapitCustomerId', e.target.value);
                    }}
                  />
                </Grid>
              )}
              {coreMeta?.integration == 'homeflow' && (
                <Grid item xs={12}>
                  <TextField
                    label={'Home Flow Token'}
                    name={'homeFlowToken'}
                    value={coreMeta?.homeFlowToken}
                    onChange={(e) => {
                      onSwitchChange('homeFlowToken', e.target.value);
                    }}
                  />
                </Grid>
              )}
              {coreMeta?.integration == 'leadpro' && (
                <Grid item xs={12}>
                  <TextField
                    label={'LeadPro API Key'}
                    name={'leadProApiKey'}
                    value={coreMeta?.leadProApiKey}
                    onChange={(e) => {
                      onSwitchChange('leadProApiKey', e.target.value);
                    }}
                  />
                </Grid>
              )}

              {coreMeta?.integration == 'acquaint' && (
                <Grid container xs={12} spacing={2} ml={1}>
                  <Grid item xs={6}>
                    <TextField
                      label={'Acquaint API Key'}
                      name={'acquaintApiKey'}
                      fullWidth
                      value={coreMeta?.acquaintApiKey}
                      onChange={(e) => {
                        onSwitchChange('acquaintApiKey', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={'Acquaint Site PreFix'}
                      name={'acquaintSitePreFix'}
                      fullWidth
                      value={coreMeta?.acquaintSitePreFix}
                      onChange={(e) => {
                        onSwitchChange('acquaintSitePreFix', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              {coreMeta?.integration == 'custom' && (
                <Grid item xs={12}>
                  <TextField
                    label={'Custom Key'}
                    name={'customKey'}
                    value={coreMeta?.customKey}
                    onChange={(e) => {
                      onSwitchChange('customKey', e.target.value);
                    }}
                  />
                </Grid>
              )}
              {coreMeta?.integration == 'alto' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      label={'Agent Key'}
                      name={'altoAgentKey'}
                      fullWidth
                      value={coreMeta?.altoAgentKey}
                      onChange={(e) => {
                        onSwitchChange('altoAgentKey', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={'Agent ID'}
                      name={'altoAgentId'}
                      fullWidth
                      value={coreMeta?.altoAgentId}
                      onChange={(e) => {
                        onSwitchChange('altoAgentId', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={'Agent Name'}
                      name={'altoAgentName'}
                      fullWidth
                      value={coreMeta?.altoAgentName}
                      onChange={(e) => {
                        onSwitchChange('altoAgentName', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={'Agent Username'}
                      name={'altoAgentUsername'}
                      fullWidth
                      value={coreMeta?.altoAgentUsername}
                      onChange={(e) => {
                        onSwitchChange('altoAgentUsername', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={'Agent Password'}
                      name={'altoAgentPassword'}
                      fullWidth
                      value={coreMeta?.altoAgentPassword}
                      onChange={(e) => {
                        onSwitchChange('altoAgentPassword', e.target.value);
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} item>
        <CardList location={companyTokens} />
      </Grid>
      <Grid xs={12} item>
        <CoreOffices locationId={props.locationId} />
      </Grid>
      <Grid xs={12} item>
        <CoreUsers locationId={props.locationId} />
      </Grid>
      <Grid xs={12} item>
        <CoreContacts locationId={props.locationId} />
      </Grid>
    </Grid>
  );
};

export default CoreDetails;
