import fetchAPI from '../../fetchAPI';

export const callGetCoreMeta = async (locationId: string): Promise<iResponse<{data: CoreMeta}>> => {
  const response: iResponse<{data: CoreMeta}> = await fetchAPI({
    method: `GET`,
    route: `core/location/${locationId}/meta-data`
  });

  return response;
};

export default callGetCoreMeta;
