const generateRows = (
  offices: CoreOfficeAddress[],
  onAction: (type: 'edit' | 'delete', office: CoreOfficeAddress) => void
): TableRow[] =>
  offices.map((office) => {
    const action: TableCellAction = {
      type: `action`,
      data: [
        {
          type: 'edit',
          linkData: {
            onClick: () => {
              onAction('edit', office);
            }
          }
        },
        {
          type: 'delete',
          linkData: {
            onClick: () => {
              onAction('delete', office);
            }
          }
        }
      ]
    };



    const visiblePostalCodes = office.postalCodes?.slice(0, 2) || [];
const extraCount = (office.postalCodes?.length || 0) - visiblePostalCodes.length;

const postalCodes: TableCellChip = {
  type: 'chip',
  data: [
    ...visiblePostalCodes.map((item) => ({
      text: item.postalCode,
      backgroundColor: '#d7d7d7'
    })),
    // Only add the extra chip if there are additional postal codes
    ...(extraCount > 0 ? [{ text: `+${extraCount}`, backgroundColor: '#d7d7d7' }] : [])
  ]
};
    const payload: TableRow = {
      officeId: office.officeId ?? '',
      location: office.location ?? '',
      readEmail: office.readEmail ?? '',
      reapitEmail: office.reapitEmail ?? '',
      robotEmail: office.robotEmail ?? '',
      mortgageEmail: office.mortgageEmail ?? ' - ',
      phone: office.phone ?? 'NA',
      postalCodes,
      action
      // linkData: {
      //   to: `${routes.SA_Core}/${token.locationId}`
      // }
    };
    return payload;
  });

export default generateRows;
