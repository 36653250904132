import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  IconButton,
  Paper,
  Grid,
  LinearProgress,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Switch,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Validator from 'validator';
import Checkbox from '@mui/material/Checkbox';
import { TextView, Button, TextField, Alert } from '../../../../atoms';

import { callCreateCompanyToken, callUpdateCompanyToken } from '../../../../particles';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';

interface AddCompanyTokenProps {
  open: boolean;
  selectedCompanyToken?: CompanyToken;
  onClose: () => void;
  onSuccess: () => void;
}

const defaultCompanyToken: {
  companyName: string;
  twilioSecret?: string;
  twilioToken?: string;
  locationId?: string;
  reapitCompanyId?: string;
  totalToken?: string;
  status: 'live' | 'staging';
  priceModel: 'low' | 'high';
  subscriptionDate: DateTime | null;
  nextSubscriptionDate: DateTime | null;
  launchDate: DateTime | null;
} = {
  companyName: '',
  twilioSecret: '',
  twilioToken: '',
  locationId: '',
  reapitCompanyId: '',
  totalToken: '25000',
  priceModel: 'high',
  status: 'staging',
  subscriptionDate: DateTime.now(),
  launchDate: null,
  nextSubscriptionDate: DateTime.now().plus({ months: 1 })
};

const defaultErrorCompanyToken: {
  companyName: string;
  twilioSecret?: string;
  twilioToken?: string;
  locationId: string;
  reapitCompanyId?: string;
  totalToken: string;
  subscriptionDate: string;
  launchDate: string;
  priceModel: string;
  status: string;
} = {
  companyName: '',
  twilioSecret: '',
  twilioToken: '',
  locationId: '',
  reapitCompanyId: '',
  totalToken: '',
  subscriptionDate: '',
  launchDate: '',
  priceModel: '',
  status: ''
};

const AddCompanyToken: React.FC<AddCompanyTokenProps> = ({
  onClose,
  open,
  onSuccess,
  selectedCompanyToken
}: AddCompanyTokenProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [checkbox, setCheckBox] = useState<boolean>(false);
  const [checkDialog, setCheckDialog] = useState<boolean>(false);
  const [values, setValues] = React.useState(defaultCompanyToken);

  const [error, setError] = useState<{
    title: string;
    message: string;
  }>();

  const [errors, setErrors] = useState(defaultErrorCompanyToken);

  useEffect(() => {
    if (selectedCompanyToken) {
      setValues({
        companyName: selectedCompanyToken.companyName,
        twilioSecret: selectedCompanyToken.twilioSecret,
        twilioToken: selectedCompanyToken.twilioToken,
        locationId: selectedCompanyToken.locationId,
        reapitCompanyId: selectedCompanyToken.reapitCompanyId,
        status: selectedCompanyToken.status ?? 'staging',
        priceModel: selectedCompanyToken.priceModel ?? 'high',
        totalToken: selectedCompanyToken.totalToken?.toString(),
        subscriptionDate: DateTime.fromISO(selectedCompanyToken.subscriptionDate),
        nextSubscriptionDate: DateTime.fromISO(selectedCompanyToken.nextSubscriptionDate),
        launchDate: selectedCompanyToken.launchDate
          ? DateTime.fromISO(selectedCompanyToken.launchDate)
          : null
      });
    }
  }, [selectedCompanyToken]);

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (prop === 'totalToken') {
      setValues({ ...values, [prop]: event.target.value.replace(/[^\d-]/g, '') });
      return;
    }

    if (prop === 'status') {
      setValues({ ...values, [prop]: event.target.checked ? 'live' : 'staging' });
      return;
    }

    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangePriceModel = (event: SelectChangeEvent) => {
    setValues({ ...values, priceModel: event.target.value as 'low' | 'high' });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      companyName,
      twilioSecret,
      twilioToken,
      totalToken,
      locationId,
      subscriptionDate,
      nextSubscriptionDate,
      launchDate,
      reapitCompanyId,
      status,
      priceModel,
    } = values;

    if (validateFields()) {
      return;
    }

    const companyToken = {
      companyName,
      twilioSecret,
      twilioToken,
      locationId,
      totalToken,
      reapitCompanyId,
      status,
      priceModel,
      subscriptionDate: subscriptionDate?.toFormat('yyyy-LL-dd'),
      nextSubscriptionDate: nextSubscriptionDate?.toFormat('yyyy-LL-dd'),
      launchDate: launchDate ? launchDate.toFormat('yyyy-LL-dd') : null,
      resetLaunchDate: checkbox,
    };

    if (selectedCompanyToken) {
      setLoading(true);
      callUpdateCompanyToken(selectedCompanyToken.id, companyToken)
        .then((res) => {
          if (res.code == 200) {
            setValues(defaultCompanyToken);
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    } else {
      setLoading(true);
      callCreateCompanyToken(companyToken)
        .then((res) => {
          if (res.code == 201) {
            setValues(defaultCompanyToken);
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    }
  };

  const validateFields = () => {
    let error = false;
    setErrors(defaultErrorCompanyToken);
    if (Validator.isEmpty(values.companyName.trim())) {
      error = true;
      setErrors((state) => ({ ...state, companyName: 'Account name is required' }));
    }

    if (!values.locationId && !values.reapitCompanyId) {
      error = true;
      setErrors((state) => ({
        ...state,
        locationId:
          'Either the Reapit Company Id or Location id field is required. Please provide at least one of them or Both.'
      }));
      setErrors((state) => ({
        ...state,
        reapitCompanyId:
          'Either the Reapit Company Id or Location id field is required. Please provide at least one of them or Both.'
      }));
    }

    if (values.locationId && Validator.isEmpty(values.locationId.trim())) {
      error = true;
      setErrors((state) => ({
        ...state,
        locationId: 'Location id should be vaild'
      }));
    }

    if (values.reapitCompanyId && Validator.isEmpty(values.reapitCompanyId.trim())) {
      error = true;
      setErrors((state) => ({
        ...state,
        reapitCompanyId: 'Reapit Company id should be vaild'
      }));
    }

    if (!Validator.isLength(values.companyName.trim(), { min: 3, max: 30 })) {
      error = true;
      setErrors((state) => ({
        ...state,
        companyName: 'Account name must be between 3 and 30 characters in length.'
      }));
    }

    return error;
  };

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        onClose();
      }}>
      <Grid md={4} sm={10} xs={10} item>
        <Paper sx={{ p: 2, width: '100%' }}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <TextView $fontSize={28}>{`${selectedCompanyToken ? 'Edit' : 'Add'} Account`}</TextView>
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                onClose();
                setValues(defaultCompanyToken);
              }}>
              <Close />
            </IconButton>
          </Box>
          {loading && <LinearProgress />}
          <form onSubmit={onSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 3 }}>
              <TextField
                label={'Location Id'}
                name={'locationId'}
                errorMessage={errors.locationId}
                fullWidth
                value={values.locationId}
                onChange={handleChange('locationId')}
              />
              <Stack direction={'row'} alignItems={'end'}>
                <TextField
                  label={'Negotiator Id'}
                  name={'reapitCompanyId'}
                  errorMessage={errors.reapitCompanyId}
                  fullWidth
                  value={values.reapitCompanyId}
                  onChange={handleChange('reapitCompanyId')}
                />
              </Stack>
              <TextField
                label={'Company Name'}
                name={'companyName'}
                errorMessage={errors.companyName}
                fullWidth
                value={values.companyName}
                onChange={handleChange('companyName')}
              />
              <Stack direction={'row'} alignItems={'end'}>
                <TextField
                  label={'Total token'}
                  name={'totalToken'}
                  fullWidth
                  errorMessage={errors.totalToken}
                  inputType="number"
                  value={values.totalToken}
                  onChange={handleChange('totalToken')}
                />
                <Stack flex={1} ml={1}>
                  <TextView $fontSize={14} $fontWeight={400}>
                    AI Model
                  </TextView>
                  <Select
                    value={values.priceModel as string}
                    onChange={handleChangePriceModel}
                    fullWidth
                    size={'small'}
                    sx={{ backgroundColor: '#ffffff', fontSize: 14, flex: 1, height: 38, mt: 1 }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem key={0} value={'high'}>
                      {'High'}
                    </MenuItem>
                    <MenuItem key={1} value={'low'}>
                      {'Low'}
                    </MenuItem>
                  </Select>
                </Stack>
              </Stack>
              <Stack direction={'row'}>
                <TextField
                  label={'Twilio Secret'}
                  name={'twilioSecret'}
                  sx={{ mr: 0.5 }}
                  errorMessage={errors.twilioSecret}
                  fullWidth
                  value={values.twilioSecret}
                  onChange={handleChange('twilioSecret')}
                />

                <TextField
                  sx={{ ml: 0.5 }}
                  label={'Twilio Token'}
                  name={'twilioToken'}
                  errorMessage={errors.twilioToken}
                  fullWidth
                  value={values.twilioToken}
                  onChange={handleChange('twilioToken')}
                />
              </Stack>

              <Stack direction={'row'} mt={2}>
                <DatePicker
                  sx={{ mr: 0.5, flex: 1 }}
                  label="Start Billing Cycle"
                  onChange={(subscriptionDate) => setValues({ ...values, subscriptionDate })}
                  value={values.subscriptionDate}
                  disabled={selectedCompanyToken != undefined}
                  minDate={selectedCompanyToken == undefined ? DateTime.now() : null}
                />
                <DatePicker
                  label="Billing Date"
                  sx={{ ml: 0.5, flex: 1 }}
                  onChange={(nextSubscriptionDate) =>
                    setValues({ ...values, nextSubscriptionDate })
                  }
                  value={values.nextSubscriptionDate}
                  minDate={values.subscriptionDate}
                />
              </Stack>

              <DatePicker
                label="Launch date"
                sx={{ mt: 2 }}
                onChange={(billing) => setValues({ ...values, launchDate: billing })}
                value={values.launchDate}
                minDate={DateTime.now()}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={checkbox}
                    checked={checkbox}
                    onChange={(_e, checked) => {
                      if (checked) {
                        setCheckDialog(true);
                      }
                      setCheckBox(false);
                    }}
                  />
                }
                label="Mark the checkbox, if you desire to reset the SR Token usage data."
              />
            </Box>

            <Dialog
              open={checkDialog}
              onClose={() => {
                setCheckBox(false);
                setCheckDialog(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{'Reset SR Token Usage Data?'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This action will clear all current usage data associated with SR Tokens. Ensure
                  that you want to proceed with the reset before confirming your selection.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setCheckBox(false);
                    setCheckDialog(false);
                  }}>
                  Disagree
                </Button>
                <Button
                  onClick={() => {
                    setCheckBox(true);
                    setCheckDialog(false);
                  }}>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            {error && <Alert severity={'error'} title={error.title} message={error.message} />}

            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                mt: 4
              }}>
              <Button
                label={`${selectedCompanyToken ? 'Update' : 'Add'}`}
                size="small"
                type="submit"
              />
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={values.status == 'live'}
                      onChange={handleChange('status')}
                    />
                  }
                  label="Is Live"
                  labelPlacement="start"
                />
              </Stack>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Modal>
  );
};
export default AddCompanyToken;
